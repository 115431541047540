import { Br, PageHeader } from "components";
import React from "react";

const CardsPageHeader = () => {
  return (
    <PageHeader
      title={
        <>
          Get all you need to create <Br on="desktop" />
          and manage your own <Br on="desktop" />{" "}
          <span className="text-primary">virtual and physical cards</span>
        </>
      }
      subheading={
        <>
          Are you building a solution that needs cards? Yes, with Parkway, you
          can <Br on="tablet" />
          issue Physical and virtual cards (Mastercard, VISA or Verve) valid in
          any <Br on="table" />
          ATM nationwide.
        </>
      }
      button={{ text: "Get Started", to: "/get-demo" }}
    />
  );
};

export { CardsPageHeader };
