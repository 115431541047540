import React from "react";
import { PageFeatures } from "components";
import { StaticImage } from "gatsby-plugin-image";

const CardsFeatures = () => {
  return (
    <div>
      <PageFeatures
        features={[
          "Prepaid card management",
          "Prompt activation & deactivation",
          "Competitive transaction charges",
          "Safe and secure",
        ]}
        image={
          <StaticImage
            src="../../../../assets/images/jpegs/cards/features.jpeg"
            alt="features"
          />
        }
      />
    </div>
  );
};

export { CardsFeatures };
