import * as React from "react";

import { Layout } from "components";
import { CardsPageHeader, CardsFeatures } from "templates/solutions/cards";

const CardsPage = () => (
  <Layout title="Cards">
    <CardsPageHeader />
    <CardsFeatures />
  </Layout>
);

export default CardsPage;
